import * as React from "react"
import { Link } from "gatsby"
import logo from "../images/logo.svg"

// markup
const NotFoundPage = () => {
  return (
    <main className="min-h-screen flex flex-col ">
      <title>Not found</title>
      <section class="py-8 md:py-16 max-w-5xl w-full mx-auto px-4 flex flex-col space-y-8 justify-center items-center text-center flex-1">
        <img
          src={logo}
          className="pointer-events-none h-32"
          alt="Eudlo General Store Logo"
        />
        <h1 className="text-3xl">Page not found</h1>
        <p>
          Sorry 😔
          <br />
          We couldn’t find what you were looking for.
        </p>
        <Link
          to="/"
          className="uppercase font-bold tracking-widest pb-1 border-b-2 border-primary"
        >
          Back home
        </Link>
      </section>
    </main>
  )
}

export default NotFoundPage
